<!-- Order -->
<template>
    <div class="card card-custom" v-loading="loading">
        <div class="card-body p-0">
            <!--begin: Orders-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Order Body-->
                <div class="row justify-content-center my-10 px-8 ">
                    <div class="col-xl-12">
                        <!--begin: Orders Form-->
                        <el-form ref="form" :model="form" auto-complete="false">
                           <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                             <h3 class="mb-10 font-weight-bold text-dark"><i class="flaticon2-digital-marketing"></i> {{title_text}}</h3>
                          <el-row>
                           <el-col :span="6"  >
                              <el-form-item label="Customer Name" prop="customer_id">
                                 <el-select ref="customer_name" v-model="form.customer_id" filterable clearable remote  @change="getCustomerAddress($event)" placeholder="Type something to search customer"  v-loading="loading" style="width:100%" :remote-method="searchCustomer">
                                    <el-option class="add-container" value="">
                                       <a class="add-drop-btn" @click="createCustomer">
                                       <i class="el-icon-circle-plus"></i>
                                       Add Customer
                                       </a>
                                    </el-option>
                                    <el-option v-for="(customer,index) in customer_list" :key="index" :label="customer.first_name+' '+customer.last_name" :value="customer.id" style="height:auto;padding: 10px 20px;">
                                    </el-option>
                                 </el-select>
                                 <el-span class="form-err" v-if="form.errors.has('customer_id')" >{{form.errors.get('customer_id')}}</el-span>
                              </el-form-item>
                           </el-col>
                           <el-col :span="5" :offset="1">
                              <el-form-item label="Store" prop="website">
                                 <el-select clearable filterable v-model="form.website_id" placeholder="Select Website"  style="width:100%" :allow-create="true" :disabled="!form.customer_id">
                                    <el-option v-for="website in active_website" :key="website.website_id" :label="website.website_name" :value="website.website_id" ></el-option>
                                 </el-select>
                                 <span class="form-err" v-if="form.errors.has('website')" >{{form.errors.get('website')}}</span>
                              </el-form-item>
                           </el-col>
                           <el-col :span="5" :offset="1">
                              <el-form-item label="Invoice Date" prop="delivery_date" style="margin-bottom:0px;"></el-form-item>
                              <el-date-picker  v-model="form.delivery_date" placeholder="DD-MM-YYYY" type="date" format="dd MMM yyyy" style="width:100%;" > </el-date-picker>
                              <span class="form-err" v-if="form.errors.has('delivery_date')" >{{form.errors.get('delivery_date')}}</span>
                           </el-col>
                           <el-col :span="5" :offset="1">
                              <el-form-item label="Payment Due Date" prop="payment_due_date" style="margin-bottom:0px;"></el-form-item>
                              <el-date-picker  v-model="form.payment_due_date" placeholder="DD-MM-YYYY" type="date" format="dd MMM yyyy" style="width:100%;" > </el-date-picker>
                              <span class="form-err" v-if="form.errors.has('payment_due_date')" >{{form.errors.get('payment_due_date')}}</span>
                           </el-col>
                        </el-row>
                        <el-row>
                           <el-col :span="6">
                              <el-form-item label="Customer Type" prop="customer_type" style="margin-bottom:0px;"></el-form-item>
                              <el-input v-model="form.customer_type" @input.native="capitalize" disabled></el-input>
                           </el-col>
                           <el-col :span="5" :offset="1">
                              <el-form-item label="Ship Via" prop="ship_via">
                                 <el-select v-model="form.ship_via" placeholder="Select" clearable>
                                    <el-option v-for="(shipvia,index) in shippingvia" :key="index" :label="shipvia.text" :value="shipvia.text" auto-complete="off">
                                    </el-option>
                                 </el-select>
                              </el-form-item>
                              <span class="form-err" v-if="form.errors.has('ship_via')" >{{form.errors.get('ship_via')}}</span> 
                           </el-col>
                           <el-col :span="5" :offset="1">
                              <el-form-item label="Shipping Method" prop="shiping_method">
                                 <el-select v-model="form.shiping_method" placeholder="Select" clearable>
                                    <el-option v-for="(shippmethod,index) in shippingmethod" :key="index" :label="shippmethod.text" :value="shippmethod.text" auto-complete="off">
                                    </el-option>
                                 </el-select>
                              </el-form-item>
                              <span class="form-err" v-if="form.errors.has('shiping_method')" >{{form.errors.get('shiping_method')}}</span>    
                           </el-col>
                           <el-col :span="5" :offset="1">
                              <el-form-item label="Type" prop="type">
                                 <el-select v-model="form.type" placeholder="Select" clearable>
                                    <el-option v-for="(shipptype,index) in shippingtype" :key="index" :label="shipptype.text" :value="shipptype.text" auto-complete="off">
                                    </el-option>
                                 </el-select>
                              </el-form-item>
                              <span class="form-err" v-if="form.errors.has('type')" >{{form.errors.get('type')}}</span> 
                           </el-col>
                        </el-row>
                        <el-row>
                           <!-- <el-col :span="6">
                              <el-form-item label="Order Status" prop="order_status">
                                 <el-select v-model="form.order_status" placeholder="Select" clearable>
                                    <el-option v-for="(status,index) in orderStatus" :key="index" :label="status.text" :value="status.id" auto-complete="off">
                                    </el-option>
                                 </el-select>
                              </el-form-item>
                              <span class="form-err" v-if="form.errors.has('order_status')" >{{form.errors.get('order_status')}}</span>
                           </el-col> -->
                           <el-col :span="6">
                              <el-form-item label="Sales Person" prop="sale_person">
                                 <el-input v-model="form.sale_person" @input.native="capitalize"></el-input>
                              </el-form-item>
                           </el-col>
                           <el-col :span="5" :offset="1">
                              <el-form-item label="Delivery Term" prop="term">
                                 <el-select filterable v-model="form.term" placeholder="Select" class="dropdown-add">
                                    <el-option class="add-container" value="">
                                       <a class="add-drop-btn" @click="CreateTerm()">
                                       <i class="el-icon-circle-plus"></i>
                                       Add Term
                                       </a>
                                    </el-option>
                                    <el-option v-for="(ct_term, index) in term_list" :key="index" :label="ct_term.term_name" :value="ct_term.term_name" auto-complete="nope">
                                    </el-option>
                                 </el-select>
                              </el-form-item>
                           </el-col>
                        </el-row>
                           
                           <el-row style="margin-top:10px;">
                              <el-col :span="12">
                                <div class="billing-adr" >Billing Address :</div>
                                <el-form-item >
                                  <el-row>
                                    <el-col :span="24" v-for="billing in billing_address" :key="billing.id" style="padding-left:5px;">                                     
                                      <div>{{billing.address_line_2}}{{billing.address_line_1 ? ', Unit No : '+billing.address_line_1 : ''}}</div>
                                      <div>{{billing.city_details?billing.city_details.city_name:''}}</div>
                                      <div>{{billing.state_details?billing.state_details.state_name:''}}{{billing.post_code ? ' - '+billing.post_code : ''}}</div>
                                      <div><el-tag size="mini"  v-on:click="bill = !bill" style="cursor:pointer">Change</el-tag></div>
                                    </el-col>
                                  </el-row>
                                  <el-select v-if="bill" clearable filterable v-model="form.billing_address_id" placeholder="Select Address"  :disabled="!form.customer_id" style="width:100%" :allow-create="true" @change="addBilling($event)" auto-complete="nope">
                                    <el-option class="add-container" value="">
                                        <a class="add-drop-btn" @click="createAddress(1)">
                                          <i class="el-icon-circle-plus"></i>
                                          Add Address
                                        </a>
                                    </el-option>   
                                    <el-option v-for="address in billingAddresses" :key="address.id" :label="addressLabel(address)" :value="address.id" ></el-option>
                                  </el-select>
                                <span class="form-err" v-if="form.errors.has('billing_address_id')" >{{form.errors.get('billing_address_id')}}</span>
                               </el-form-item>    
                              </el-col>
                              <el-col :span="11" :offset="1">
                                 <div class="billing-adr" >Shipping Address :</div>
                                 <el-form-item prop="shipping_address_id">
                                    <el-row>
                                    <el-col :span="24" v-for="shipping in shipping_address" :key="shipping.id" style="padding-left:5px;">                                     
                                      <div>{{shipping.address_line_2}}{{shipping.address_line_1 ? ', Unit No : '+shipping.address_line_1 : ''}}</div>
                                      <div>{{shipping.city_details?shipping.city_details.city_name:''}}</div>
                                      <div>{{shipping.state_details?shipping.state_details.state_name:''}}{{shipping.post_code ? ' - '+shipping.post_code: ''}}</div>
                                      <div><el-tag size="mini"  v-on:click="ship = !ship" style="cursor:pointer">Change</el-tag></div>
                                    </el-col>
                                  </el-row>
                                    <el-select v-if="ship" clearable filterable v-model="form.shipping_address_id" placeholder="Select Address"  :disabled="!form.customer_id" :allow-create="true" style="width:100%" @change="addShipping($event)" auto-complete="nope">
                                      <el-option class="add-container" value="">
                                          <a class="add-drop-btn" @click="createAddress(2)">
                                            <i class="el-icon-circle-plus"></i>
                                            Add Address
                                          </a>
                                      </el-option>   
                                       <el-option v-for="address in shippingAddresses" :key="address.id" :label="addressLabel(address)" :value="address.id" ></el-option>
                                    </el-select>
                                    <span class="form-err" v-if="form.errors.has('shipping_address_id')" >{{form.errors.get('shipping_address_id')}}</span>
                                 </el-form-item>
                              </el-col>
                           </el-row>
                           <div class="row">
                              <div class="col-xl-12"><h4>Products<el-tag size="mini" style="margin-left:10px;"><a href="#" @click.prevent="createProduct">Add Product</a></el-tag></h4>                                
                                 <hr>
                              </div>
                           </div>
                           <el-form-item prop="products">                              
                              <el-table :data="form.products" stripe border fit >
                                  <el-table-column
                                    type="index"
                                    width="30">
                                  </el-table-column>
                                  <el-table-column label="Product #" width="120">
                                      <template slot-scope="scope">
                                          <el-select filterable remote v-model="form.products[scope.$index].product_id" placeholder="Select" style="width:100%" @change="setProductPrice(scope.$index)" :remote-method="searchProduct" >
                                            <el-option v-for="(product,index) in product_price" :key="index" :label="product.product_id" :value="product.id" auto-complete="off"  style="height:auto;padding: 10px 20px;" :disabled="isExist(product.id)" >
                                            </el-option>
                                        </el-select>
                                      </template>
                                  </el-table-column>
                                  <el-table-column label="Product Name" >
                                      <template slot-scope="scope">
                                         <el-select filterable  v-model="form.products[scope.$index].product_id" placeholder="Select" style="width:100%" @change="setProductPrice(scope.$index)" :remote-method="searchProduct" >
                                             <el-option v-for="(product,index) in product_price" :key="index" :label="product.name" :value="product.id" auto-complete="off"  style="height:auto;padding: 10px 20px;" :disabled="isExist(product.id)">
                                            </el-option>
                                        </el-select>
                                      </template>
                                  </el-table-column>
                                  <el-table-column label="Quantity" width="130">
                                      <template slot-scope="scope">
                                          <el-input-number v-model="form.products[scope.$index].quantity" placeholder="0" :min="0" :step="1" @change="setProductPrice(scope.$index)"></el-input-number>
                                      </template>
                                  </el-table-column>
                                  <el-table-column label="Unit Price" width="100">
                                      <template slot-scope="scope">
                                          <el-input v-model="form.products[scope.$index].unit_price" placeholder="0" controls-position="right"  @change="setProductPrice(scope.$index)" disabled></el-input>
                                      </template>
                                  </el-table-column>

                                  <el-table-column label="Tax" width="120"> 
                                    <template slot-scope="scope">                                       
                                      <el-select filterable v-model="form.products[scope.$index].tax_id" placeholder="Select" @change="setProductPrice(scope.$index)" :disabled="!form.products[scope.$index].product_id" clearable>
                                            <el-option class="add-container" value="">
                                              <a class="add-drop-btn" @click="createTax(scope.$index)">
                                                <i class="el-icon-circle-plus"></i>
                                                Add Tax
                                              </a>
                                            </el-option>
                                            <el-option v-for="(tax,index) in form.products[scope.$index].tax_list" :key="index" :label="tax.tax_name+'('+tax.tax_amount+'%)'" :value="tax.tax_id" auto-complete="off">
                                            </el-option>
                                        </el-select>
                                    </template>
                                  </el-table-column>
                                  <el-table-column label="Discount(%)" width="130">
                                      <template slot-scope="scope">
                                          <el-input-number v-model="form.products[scope.$index].extra_discount" placeholder="0" :min="0" :max="100" @change="setProductPrice(scope.$index)"></el-input-number>
                                      </template>
                                  </el-table-column>
                                  <el-table-column label="Total Price" width="100">
                                      <template slot-scope="scope">
                                          <el-input v-model="form.products[scope.$index].total_price" placeholder="0" controls-position="right" disabled></el-input>
                                      </template>
                                  </el-table-column>
                                  <el-table-column label="" width="50">
                                      <template slot-scope="scope">
                                            <i v-if="scope.$index == (form.products.length - 1) && scope.$index > 0" @click="removeProduct(scope.$index)" class="el-icon-circle-close icon-pointer"></i>
                                            <i v-if="scope.$index == (form.products.length - 1)" @click="addProduct(scope.$index)" class="el-icon-circle-plus icon-pointer"></i>
                                            <i v-else @click="removeProduct(scope.$index)" class="el-icon-circle-close icon-pointer"></i>
                                      </template>
                                  </el-table-column>
                                </el-table>
                              <span class="form-err" v-if="show_product_error" >Please select a product.</span>
                              <span class="form-err" v-if="show_product_qty_error" >Please quantity must be grater than 0.</span>
                           </el-form-item>
                           
                           <div class="row">
                              <div class="col-xl-12"><h4>Pricing Details</h4>                              
                              </div>
                           </div>
                           <el-row class="pricing-section">
                              <el-col :span="16" >
                                <el-row>
                                  <el-col :span="10" >
                                      <el-form-item > 
                                        <el-row  v-if="user_credits > 0">
                                            <el-col :span="10"><b>Credits : </b> <span style="color:green">{{user_credits}}</span> </el-col>
                                             <el-col :span="10" :offset="2"><b>Balance : </b><span style="color:red">{{balance_credit}}</span></el-col>
                                        </el-row>  
                                        <el-input-number v-if="user_credits > 0" v-model="form.credit_amount" auto-complete="off" @change="calculateTotal()" :min="0" :max="user_credits? user_credits:''"></el-input-number>
                                      </el-form-item>
                                      <span class="form-err" v-if="show_credit_error" >Credit amount used {{form.total}} Only</span>
                                  </el-col>
                                   <el-col :span="10" :offset="1">                                      
                                  </el-col>
                                </el-row>
                                    <el-col :span="20">
                                      <el-form-item label="Other Comments or Special Instructions" prop="comments">
                                      <el-input type="textarea" :rows="4"  v-model="form.comments" @input.native="capitalize"></el-input>
                                      </el-form-item>  
                                    </el-col>   
                              </el-col> 
                              <el-col :span="8" >
                                <el-form >
                                 <el-form-item label="Sub Total" prop="sub_total" >
                                    <el-input v-model="form.sub_total" auto-complete="off" disabled class="sub-total"></el-input>
                                 </el-form-item>   
                                 <el-form-item label="Tax Total" prop="tax_total">
                                    <el-input v-model="form.tax_total" auto-complete="off" disabled class="sub-total"></el-input>
                                 </el-form-item>                                
                                   <!-- <el-form-item label="Contractor Discount(-)" prop="discount">
                                        <el-input v-model="form.discount" auto-complete="off"  :min="0" disabled></el-input>
                                    </el-form-item> -->
                                 <el-form-item label="Total" prop="tax_total">
                                    <el-input v-model="form.total" auto-complete="off" disabled class="sub-total"></el-input>
                                 </el-form-item>
                                </el-form>
                              </el-col>
                           </el-row>
                            <el-form-item style="margin-top:25px;">
                              <el-button type="primary" @click="createInvoice">{{submit_btn_text}}</el-button>
                              <el-button  type="warning" @click.prevent="reset()" >Reset</el-button>
                            </el-form-item>
                           </div>
                        </el-form>
                        <!--end: Orders Form-->
                    </div>
                </div>
                <!--end: Orders Body-->
            </div>
        </div>        
        <!--end: Orders-->
    </div>
</template>


<script>
import {
    SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services'
//import { getCityList, getStateList, getCityListByStateId } from '@/api/common'
import {
    getEditorder,
    searchCustomer,
    getCustomerAddress,
    getWebsite,
    getOrderTerm,
    searchProduct
} from "@/api/order";
import {
    getAllCustomers,
    getCustomerCredit
} from "@/api/customer";
import {
    getProductsForDropdown
} from "@/api/product";
import createProduct from '@/components/CreateProduct/add.vue';
import createCustomer from '@/components/CreateCustomer/index.vue';
import CreateAddress from '@/components/CreateAddress/addAddress';
import CreateTerm from '@/components/CreateOrderTerm';
import {
    globalEvents
} from '@/utils/globalEvents';
import {
    getAllActiveSites
} from "@/api/website";
import createTax from '@/components/CreateTax/addTax.vue';
import JwtService from "@/core/services/jwt.service";
import * as moment from 'moment';
export default {
    name: 'add-order',
    data() {
        return {
            loading: false,
            loadingCustomer: false,
            error: null,
            bill: true,
            ship: true,
            form: new Form({
                customer_id: null,
                customer_type: null,
                customer_typeId: null,
                delivery_date: new Date(),
                payment_due_date: new Date(),
                billing_address_id: null,
                shipping_address_id: null,
                ship_via: null,
                sale_person: null,
                shiping_method: null,
                order_by: null,
                type: null,
                term: null,
                sub_total: '0.00',
                discount: '0.00',
                credit_amount: '',
                order_status: 'completed',
                website_id: null,
                comments: null,
                tax_total: '0.00',
                total: '0.00',
                products: [{
                    product_id: null,
                    quantity: null,
                    unit_price: '0.00',
                    tax_id: null,
                    tax_amount: '0.00',
                    total_price: '0.00',
                    stock_quantity: 0,
                    discount: '0,00',
                    tax_list: [],
                    cont_discount: '0.00',
                    extra_discount: null,
                }]
            }),
            search: '',
            addresses: [],
            city_list: [],
            state_list: [],
            customer_list: [],
            product_list: [],
            active_website: [],
            product_price: [],
            billing_address: [],
            shipping_address: [],
            term_list: [],
            quantity: [],
            searchKeyword: '',
            logo_btn_text: null,
            submit_btn_text: 'Create Invoice',
            title_text: 'Create Invoice',
            api_url: '/invoice/create',
            response_msg: 'Invoice created successfully.',
            shippingmethod: [{
                    id: 1,
                    text: "Free Shipping"
                },
                {
                    id: 2,
                    text: "Flat Rate"
                },
                {
                    id: 3,
                    text: "Ship by"
                },
                {
                    id: 4,
                    text: "Pickup In Store"
                }
            ],
            shippingvia: [{
                    id: 1,
                    text: "Truck"
                },
                {
                    id: 2,
                    text: "Flight"
                },
                {
                    id: 3,
                    text: "Ship"
                },
                {
                    id: 4,
                    text: "Train"
                }
            ],
            shippingtype: [{
                    id: 1,
                    text: "Single"
                },
                {
                    id: 2,
                    text: "Bulk"
                },
                {
                    id: 3,
                    text: "Back order"
                }
            ],
            orderStatus: [{
                    id: "pending",
                    text: "Pending Payment"
                },
                {
                    id: "processing",
                    text: "Processing"
                },
                {
                    id: "on-hold",
                    text: "On Hold"
                },
                {
                    id: "completed",
                    text: "Completed"
                },
                {
                    id: "cancelled",
                    text: "Cancelled"
                },
                {
                    id: "refunded",
                    text: "Refunded"
                },
                {
                    id: "failed",
                    text: "Failed"
                },
            ],
            types: ['date'],
            options: {},
            show_product_error: false,
            show_product_qty_error: false,
            show_credit_error: false,
            user_credits: 0,
            balance_credit: null,
            current_product_index: null
        }
    },
    components: {},
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{
                title: "Dashboard",
                route: "/dashboard"
            },
            {
                title: "Invoice",
                route: "/invoice/list"
            },
            {
                title: "Add/Edit Invoice"
            }
        ]);

        globalEvents.$on('productAdd', added => {
            if (added) {
                this.product_price.push(added)
                this.form.products.push({
                    product_id: added.id,
                    quantity: null,
                    unit_price: '0.00',
                    tax_id: null,
                    tax_amount: '0.00',
                    total_price: '0.00',
                    stock_quantity: 'null',
                    cont_discount: '0.00',
                    extra_discount: null,
                    tax_list: [],
                });
            }
        });

        globalEvents.$on('CustomerAdded', added => {
            if (added) {
                this.customer_list.push(added);
                this.form.customer_id = added.id;
                this.loading = true
                this.getCustomerAddress();
                this.getCustomerCredit();
            }
        });

        globalEvents.$on('AddressAdded', added => {
            if (added) {
                this.addresses.push(added);
                if (added.type == 'billing') {
                    this.form.billing_address_id = added.id
                    this.billing_address.push(added);
                    this.bill = false;
                } else {
                    this.form.shipping_address_id = added.id
                    this.shipping_address.push(added);
                    this.ship = false;
                }
            }
        });

        globalEvents.$on('AddTax', tax => {
            if (tax) {
                this.form.products[this.current_product_index].tax_list.push(tax);
                this.form.products[this.current_product_index].tax_id = tax.tax_id;
                this.setProductPrice(this.current_product_index);
                this.current_product_index = null;
            }
        });

        globalEvents.$on('TermAdded', term => {
            if (term) {
                this.term_list.push(term);
                this.form.term = term.id;
            }
        });
    },

    methods: {
        format_date(date){
          const d = moment(date)
          return (d.isValid()) ? d.format('YYYY-MM-DD') : ''
        },
        setValueNull() {
            this.value_ = null;
            this.form.customer_typeId = ''
            this.form.customer_type = '';
            this.form.website_id = '';
            this.getAllCustomers();
        },

        addTableRow: function() {
            this.counter++;
            this.tableRows.push("Table Row " + this.counter);
        },
        availableQuantity(product_id) {
            let total_stock = this.getStockQuantity(product_id);
            if (this.$route.params.orderId) {
                this.quantity.map((value) => {
                    if (value.product_id == product_id) {
                        total_stock = parseInt(value.quantity) + parseInt(this.getStockQuantity(product_id));
                    }
                })
            }

            return total_stock;
        },
        isExist(product_id) {
            for (var i = 0; i < this.form.products.length; i++) {
                if (this.form.products[i].product_id == product_id) {
                    return true
                }
            }
            return false
        },
        reset() {
            this.$refs['form'].resetFields()
        },
        addressLabel(address) {
            let formattedAddress = '';
            if (address.company_name) {
                formattedAddress += address.company_name + ', ';
            }
            if (address.address_line_1) {
                formattedAddress += address.address_line_1 + ', ';
            }
            if (address.address_line_2) {
                formattedAddress += address.address_line_2 + ', ';
            }
            if (address.city) {
                formattedAddress += address.city_details.city_name + ', ';
            }
            if (address.state) {
                formattedAddress += address.state_details.state_name + ', ';
            }
            if (address.post_code) {
                formattedAddress += address.post_code + ', ';
            }
            return formattedAddress;
        },


        setProductPrice(index) {
            let pid = this.form.products[index].product_id;
            let unit_price = this.getProductPriceById(pid);
            let quantity = parseInt(this.form.products[index].quantity);
            let extra_discount = (unit_price * (this.form.products[index].extra_discount / 100));
            let tax_percentage = 0;
            let unit_tax_amount = 0;
            let tax_id = parseInt(this.form.products[index].tax_id);
            let stock = this.getStockQuantity(pid);

            if (this.$route.params.orderId) {
                this.quantity.map((value) => {
                    if (value.product_id == pid) {
                        stock = parseInt(value.quantity) + parseInt(this.getStockQuantity(pid));
                    }
                })
            }
            /*if (stock == quantity) {
                const h = this.$createElement;
                this.$notify({
                    message: h('i', {
                        style: 'color: red'
                    }, 'Product Out of Stock'),
                    offset: 200,
                    type: 'warning'
                });
            }*/
            if (tax_id) {
                tax_percentage = this.getTaxAmountByTaxId(tax_id, index);
            }
            if (tax_percentage > 0) {
                unit_tax_amount = this.calculateTaxAmount(unit_price, tax_percentage);
            }
            this.form.products[index].stock_quantity = stock;
            this.form.products[index].tax_list = this.getProductTaxListById(pid);
            this.form.products[index].unit_price = unit_price;
            this.form.products[index].tax_amount = quantity * unit_tax_amount;
            this.form.products[index].total_price = ((quantity * unit_price) + (quantity * unit_tax_amount) - (quantity * extra_discount)).toFixed(2);
            this.form.products[index].price = (parseInt(this.form.products[index].quantity) * this.getProductPriceById(pid)).toFixed(2);
            this.form.products[index].cont_discount = quantity * this.getContractorDiscount(pid).toFixed(2);
            this.calculateTotal();
        },
        getProductPriceById(product_id) {
            let productPrice = 0;
            if (product_id) {
                this.product_price.map((product) => {
                    //console.log(index+"--"+product_id+"---"+product.id);
                    if (product_id == product.id) {
                        if (product.regular_price != '' || product.regular_price == null) {
                            if (this.form.customer_typeId == 1) {
                                productPrice = parseFloat(product.contractor_price);
                            } else if (this.form.customer_typeId == 2) {
                                productPrice = parseFloat(product.dealer_amount);
                            } else {
                                productPrice = parseFloat(product.regular_price);
                            }

                        } else {
                            productPrice = parseFloat(product.cost_price);
                        }
                    }
                })
            }
            return productPrice;
        },
        getStockQuantity(product_id) {
            let stock = 0;
            if (product_id) {
                this.product_price.map((product) => {
                    if (product_id == product.id) {
                        stock = product.stock_quantity;
                    }
                })
            }
            return stock;
        },


        getContractorDiscount(product_id) {
            let discount = 0;
            if (product_id) {
                this.product_price.map((product) => {
                    if (product_id == product.id) {
                        if (this.form.customer_typeId == 1) {

                            discount = (product.regular_price - product.contractor_price);
                        }
                    }
                })
            }
            return discount;
        },
        getTaxAmountByTaxId(tax_id, pindex) {
            let tax_amount = 0;
            if (tax_id) {
                this.form.products[pindex].tax_list.map((tax) => {
                    if (tax_id == tax.tax_id) {
                        tax_amount = parseFloat(tax.tax_amount);
                    }
                })
            }
            return tax_amount;
        },
        calculateTaxAmount(unit_product_price, tax_percentage) {
            let tax_amount = 0;
            if (tax_percentage > 0) {
                tax_amount = (unit_product_price * tax_percentage) / 100;
            }
            return tax_amount;
        },
        getProductTaxListById(product_id) {
            let tax_list = 0;
            if (product_id) {
                this.product_price.map((product) => {
                    if (product_id == product.id) {
                        tax_list = product.tax_list;
                    }
                })
            }
            return tax_list;
        },

        searchCustomer(q) {
            if (q !== '') {
                //this.loadingCustomer = true;
                searchCustomer(q).then(response => {
                    //this.loadingCustomer = false;
                    this.customer_list = response.data.data;
                }).catch(error => {
                    console.log(error);
                    //this.loadingCustomer = false;
                    this.customer_list = [];
                })
            } else {
                this.customer_list = [];
            }
        },
        searchProduct(q) {
            if (q !== '') {
                //this.loadingProduct = true;
                searchProduct(q).then(response => {
                    //this.loadingProduct = false;
                    this.product_price = response.data.data;
                }).catch(error => {
                    console.log(error);
                    //this.loadingProduct = false;
                    this.product_price = [];
                })
            } else {
                this.product_price = [];
            }
        },
        customFilter(item, queryText) {
            const textOne = (item.first_name) ? item.first_name.toLowerCase() : "";
            const textTwo = (item.last_name) ? item.last_name.toLowerCase() : "";
            const searchText = (queryText) ? queryText.toLowerCase() : "";
            return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
        },
        productNameFilter(item, queryText) {
            const textOne = (item.description) ? item.description.toLowerCase() : "";
            const searchText = (queryText) ? queryText.toLowerCase() : "";
            return textOne.indexOf(searchText) > -1;
        },
        productSkuFilter(item, queryText) {
            const textOne = (item.sku) ? item.sku.toLowerCase() : "";
            const searchText = (queryText) ? queryText.toLowerCase() : "";
            return textOne.indexOf(searchText) > -1;
        },
        createInvoice: function() {
            let errMsg = false;
            let errMsgQty = false;
            let errCredit = false;
            if (JwtService.getAttribute()) {
                let updatedUserdata = JSON.parse(JwtService.getAttribute());
                this.form.order_by = updatedUserdata.first_name + ' ' + updatedUserdata.last_name
            }
            this.form.products.forEach(function(value) {
                if (value.product_id == null) {
                    errMsg = true;
                }
                if (value.quantity == 0) {
                    errMsgQty = true;
                }

            });

            if (this.form.total == this.credit_amount) {
                errCredit = true;
            }

            if (errMsg) {
                this.show_product_error = true;
                return false;
            } else {
                this.show_product_error = false;
                if (errMsgQty) {
                    this.show_product_qty_error = true;
                    return false;
                } else {
                    this.show_product_qty_error = false;
                }
            }
            if (errCredit) {
                this.show_credit_error = true;
                return false;
            }

            this.loading = true;
            this.form.delivery_date = this.format_date(this.form.delivery_date);
            this.form.payment_due_date = this.format_date(this.form.payment_due_date, 'YYYY-MM-DD');
            this.form.post(this.api_url).then((response) => {
                if (response.status) {
                    this.$router.push({
                        path: '/invoice/list'
                    });
                    this.loading = false;
                    this.$showResponse('success', response.message);
                }
            }).catch((error) => {
                this.loading = false;
                console.log(error)
            })
        },
        createCustomer() {
            this.$modal.show(createCustomer, {}, {
                draggable: true,
                transition: 'pop-out',
                overflow: 'auto',
                height: 'inherit',
                clickToClose: false,
                adaptive: true,
                width: '800px'
            })
        },

        createProduct() {
            this.$modal.show(createProduct, {
                //customer_id: row.customer_address[0].customer_id
            }, {
                draggable: true,
                transition: 'pop-out',
                overflow: 'auto',
                height: 'inherit',
                clickToClose: false,
                adaptive: true,
                width: '1000px'
            })
        },
        createAddress(val) {
            var titleName = 'Shipping Address';
            if (val == '1') {
                titleName = 'Billing Address'
            }
            this.$modal.show(CreateAddress, {
                customer_id: this.form.customer_id,
                address_type: val,
                label: {
                    button: 'Create',
                    title: titleName
                }
            }, {
                draggable: true,
                transition: 'pop-out',
                overflow: 'auto',
                height: 'auto',
                clickToClose: false,
                adaptive: true,
                width: '600px'
            })
        },

        getAllCustomers() {
            this.loading = true
            getAllCustomers().then(response => {
                //console.log(response);        
                this.customer_list = response.data.data.data
                this.loading = false
            })
        },

        changeItem: function changeItem(rowId, event) {
            this.selected = "rowId: " + rowId + ", target.value: " + event.target.value;
        },
        getOrderTerm() {
            this.loading = true
            getOrderTerm().then(response => {
                this.term_list = response.data.data
                this.loading = false
            })
        },

        addProduct() {
            this.form.products.push({
                product_id: null,
                quantity: null,
                unit_price: '0.00',
                tax_id: null,
                tax_amount: null,
                total_price: null,
                stock_quantity: 0,
                discount: '0,00',
                tax_list: [],
                cont_discount: '0.00',
                extra_discount: null,
            });
        },

        removeProduct(index) {
            this.form.products.splice(index, 1);
            //this.setProductPrice(index);
            this.calculateTotal();
        },

        calculateTotal() {
            let sub_total = 0;
            let tax_total = 0;
            let ct_discount = 0;
            let credit = this.form.credit_amount;
            this.form.products.map((product) => {
                sub_total = sub_total + ((product.unit_price * product.quantity) - (product.unit_price * (product.extra_discount / 100)));
                tax_total = tax_total + product.tax_amount;
                ct_discount = ct_discount + product.cont_discount;

            })

            this.form.sub_total = parseFloat(sub_total).toFixed(2);
            this.form.tax_total = parseFloat(tax_total).toFixed(2);
            this.form.discount = parseFloat(ct_discount).toFixed(2);
            this.form.total = parseFloat(sub_total + tax_total - credit).toFixed(2);


        },

        getCustomerAddress() {

            this.form.billing_address_id = '';
            this.form.shipping_address_id = '';
            this.billing_address = [];
            this.shipping_address = [];
            this.product_price = [];
            this.form.products = [];
            this.form.sub_total = '0.00';
            this.form.discount = '0.00';
            this.form.tax_total = '0.00';
            this.form.total = '0.00';
            this.bill = true;
            this.ship = true;
            this.user_credits = '';

            if (this.form.customer_id) {
                //this.loading = true
                this.form.customer_typeId = ''
                this.form.customer_type = '';
                this.customer_list.map((customer) => {
                    if (this.form.customer_id == customer.id) {

                        if (customer.customer_type) {
                            this.form.customer_typeId = customer.type_id;
                            this.form.customer_type = customer.customer_type.type_name
                        }
                    }

                });
                getCustomerAddress(this.form.customer_id).then(response => {
                    //this.loading = false
                    this.addresses = response.data.data;
                });

                this.getWebsite();
                this.getCustomerCredit();
                this.getProductsForDropdown();
            }
        },
        getCustomerCredit() {
            this.loading = true;
            getCustomerCredit(this.form.customer_id).then(response => {
                this.loading = false;
                if (response.data.data) {
                    this.user_credits = response.data.data.available_credit;
                    this.balance_credit = response.data.data.balance_showing
                }
            });
        },
        getWebsite() {
            this.loading = true
            getWebsite(this.form.customer_id).then(response => {
                this.form.website_id = parseInt(response.data.data.website_id)
                this.loading = false
                if (!this.$route.params.orderId) {
                    this.addProduct();
                }

            })
        },
        getProductsForDropdown() {
            this.loading = true;
            getProductsForDropdown().then(response => {
                this.loading = false
                this.product_price = response.data.data
            });
        },

        getAllActiveSites() {
            this.loading = true
            getAllActiveSites().then(response => {
                this.active_website = response.data.data
                this.loading = false
            })
        },


        createTax(index) {
            this.current_product_index = index;
            this.$modal.show(createTax, {}, {
                draggable: true,
                transition: 'pop-out',
                overflow: 'auto',
                height: 'auto',
                clickToClose: false,
                adaptive: true,
                width: '400'
            })
        },
        CreateTerm() {
            this.$modal.show(CreateTerm, {}, {
                draggable: true,
                transition: 'pop-out',
                height: 'auto',
                clickToClose: true,
                adaptive: true,
                width: '450px'
            });
        },
        addBilling() {
            let billing_id = this.form.billing_address_id;
            this.billing_address = [];
            this.addresses.map((billing) => {
                if (billing.type == 'billing') {
                    if (billing_id == billing.id) {
                        this.billing_address.push(billing);
                        this.bill = false;
                    }
                }
            });
        },
        addShipping() {
            let shipping_id = this.form.shipping_address_id;
            this.shipping_address = [];
            this.addresses.map((billing) => {
                if (billing.type == 'shipping') {
                    if (shipping_id == billing.id) {
                        this.shipping_address.push(billing);
                        this.ship = false;
                    }
                }
            });
        }

    },
    watch: {

        search(value) {
            if (!value) {
                return
            }
            //this.loading = true      
            searchCustomer(value).then(response => {
                this.customer_list = response.data.data
                // this.loading = false
            })

            searchProduct(value).then(response => {
                this.product_price = response.data.data;
                // this.loading = false
            })
            this.getCustomerAddress();
        },

    },
    created() {
        let orderId = this.$route.params.orderId;

        if (parseInt(orderId) > 0) {
            this.submit_btn_text = 'Update Order'
            this.title_text = 'Update Order'
            this.action = 'update'
            this.response_msg = 'Order updated successfully.'
            getEditorder(orderId).then(response => {
                this.api_url = 'order/update/' + response.data.data.id;
                this.form = new Form(response.data.data);
                this.quantity = response.data.data.sorder_items;
                this.form.products = response.data.data.order_items;
                this.billing_address.push(response.data.data.billing_address_order);
                this.shipping_address.push(response.data.data.shipping_address_order)
                this.bill = false;
                this.ship = false;
                this.getWebsite();
                getCustomerAddress(this.form.customer_id).then(response => {
                    this.loading = false
                    this.addresses = response.data.data;
                });
                this.getCustomerCredit();
                this.getOrderTerm();
                this.getProductsForDropdown();
            });

        };
        //this.getStateList();
        //this.getCityList();    
        this.getAllCustomers();
        // this.getAllproductlists();
        this.getAllActiveSites();
        this.getOrderTerm();
    },
    computed: {
        shippingAddresses() {
            return this.addresses.filter(addr => {
                return addr.type == 'shipping'
            });
        },
        billingAddresses() {
            return this.addresses.filter(addr => {
                return addr.type == 'billing'
            });
        }
    },

} 
</script>

<style>
   .sub-total.el-input__inner {
      color: black!important;
   }

   .billing-adr {
      font-size: 15px;
      background-color: #366092; 
      color: #ffffff;
      padding: 3px;
      border: 1px solid #366092;
   }
</style>

